import { FormikProps, getIn } from 'formik';
import { PaymentInfoFormType } from 'types/AppointmentNewTypes';
import React, { useState } from 'react';
import * as S from 'pages/Billing/components/Claims/components/ClaimForm/styles';
import { TooltipDatePicker, Dialog, TooltipInput, TooltipSelectInput } from 'components';
import { DeleteRedBtnSVG, SearchIconSVG } from 'assets/icons';
import { ChooseCptCode } from './ChooseCptCode';
import { FieldLookup } from './FieldLookup';
import dayjs from 'dayjs';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { TooltipNumberInput } from '../../../../../../../../components/atoms/Input/TooltipNumberInput';

type Props = {
  row: any;
  header: string;
  index: number;
  paymentFormik: FormikProps<Partial<PaymentInfoFormType>>;
  checkStatusCondition?: boolean;
};

export const ServicesRow = ({
  checkStatusCondition,
  row,
  header,
  index,
  paymentFormik,
}: Props) => {
  const [showPlaceOfService, setShowPlaceOfService] = useState(false);
  const [showCpt, setShowCpt] = useState(false);
  //const [showCptForModifier, setShowCptForModifier] = useState<number | null>(null);
  switch (header) {
    case 'placeOfService': {
      return (
        <>
          <S.InputWrapper className={'all'}>
            <TooltipInput
              label=""
              value={row[header]}
              disabled={checkStatusCondition}
              onChange={e =>
                paymentFormik.setFieldValue(
                  `services[${index}].placeOfService`,
                  e.target.value,
                )
              }
              isSum={
                <S.Btn
                  onClick={
                    !checkStatusCondition ? () => setShowPlaceOfService(true) : undefined
                  }
                >
                  <SearchIconSVG className="svg" width={20} />
                </S.Btn>
              }
              error={!!getIn(paymentFormik.errors, `services[${index}].placeOfService`)}
              tooltipHelperText={getIn(
                paymentFormik.errors,
                `services[${index}].placeOfService`,
              )}
            />
          </S.InputWrapper>

          <Dialog open={showPlaceOfService} onClose={() => setShowPlaceOfService(false)}>
            <FieldLookup
              onApplyClick={code => {
                paymentFormik.setFieldValue(`services[${index}].placeOfService`, code);
                setShowPlaceOfService(false);
              }}
              currentValue={
                paymentFormik.values?.services
                  ? paymentFormik.values?.services[index]?.placeOfService
                  : ''
              }
            />
          </Dialog>
        </>
      );
    }
    case 'dateFrom': {
      return (
        <S.InputWrapper className={'px180'}>
          <TooltipDatePicker
            label=""
            disabled={checkStatusCondition}
            value={dayjs(row.dateFrom)}
            onChange={date =>
              paymentFormik.setFieldValue(`services[${index}].dateFrom`, date)
            }
            error={!!getIn(paymentFormik.errors, `services[${index}].dateFrom`)}
            tooltipHelperText={getIn(paymentFormik.errors, `services[${index}].dateTo`)}
          />
        </S.InputWrapper>
      );
    }
    case 'dateTo': {
      return (
        <S.InputWrapper className={'px180'}>
          <TooltipDatePicker
            label=""
            disabled={checkStatusCondition}
            value={dayjs(row.dateTo)}
            onChange={date =>
              paymentFormik.setFieldValue(`services[${index}].dateTo`, date)
            }
            error={!!getIn(paymentFormik.errors, `services[${index}].dateTo`)}
            tooltipHelperText={getIn(paymentFormik.errors, `services[${index}].dateTo`)}
          />
        </S.InputWrapper>
      );
    }
    case 'modifier': {
      return (
        <>
          <S.ModifierWrapper>
            {Array.from(new Array(4), (_, i) => (
              <S.InputWrapper className={'all'} key={i}>
                <TooltipInput
                  disabled={checkStatusCondition}
                  label=""
                  value={row[header][i]}
                  onChange={e =>
                    paymentFormik.setFieldValue(
                      `services[${index}].modifier[${i}]`,
                      e.target.value,
                    )
                  }
                  error={
                    !!getIn(paymentFormik.errors, `services[${index}].modifier[${i}]`)
                  }
                  tooltipHelperText={getIn(
                    paymentFormik.errors,
                    `services[${index}].modifier[${i}]`,
                  )}
                  /*isSum={
                    <S.Btn onClick={() => setShowCptForModifier(i)}>
                      <SearchIconSVG className="svg" width={20} />
                    </S.Btn>
                  }*/
                />
              </S.InputWrapper>
            ))}
          </S.ModifierWrapper>

          {/*<Dialog
            open={showCptForModifier !== null}
            onClose={() => setShowCptForModifier(null)}
          >
            <ChooseCptCode
              onApplyClick={code => {
                paymentFormik.setFieldValue(
                  `services[${index}].modifier[${showCptForModifier}]`,
                  code,
                );
                setShowCptForModifier(null);
              }}
              currentValue={
                paymentFormik.values?.services && showCptForModifier
                  ? paymentFormik.values?.services[index]?.modifier[showCptForModifier]
                  : ''
              }
            />
          </Dialog>*/}
        </>
      );
    }
    case 'cpt': {
      return (
        <>
          <S.InputWrapper className={'all'}>
            <TooltipInput
              label=""
              disabled={checkStatusCondition}
              value={row[header]}
              onChange={e =>
                paymentFormik.setFieldValue(`services[${index}].cpt`, e.target.value)
              }
              isSum={
                <S.Btn
                  onClick={!checkStatusCondition ? () => setShowCpt(true) : undefined}
                >
                  <SearchIconSVG className="svg" width={20} />
                </S.Btn>
              }
              error={!!getIn(paymentFormik.errors, `services[${index}].cpt`)}
              tooltipHelperText={getIn(paymentFormik.errors, `services[${index}].cpt`)}
            />
          </S.InputWrapper>
          <Dialog open={showCpt} onClose={() => setShowCpt(false)}>
            <ChooseCptCode
              onApplyClick={code => {
                paymentFormik.setFieldValue(`services[${index}].cpt`, code);
                setShowCpt(false);
              }}
              currentValue={
                paymentFormik.values?.services
                  ? paymentFormik.values?.services[index]?.cpt
                  : ''
              }
            />
          </Dialog>
        </>
      );
    }
    case 'charges': {
      return (
        <S.InputWrapper className={'all'}>
          <TooltipNumberInput
            label=""
            disabled={checkStatusCondition}
            value={row[header]}
            onChange={e =>
              paymentFormik.setFieldValue(
                `services[${index}][${header}]`,
                e.target.value.toString(),
              )
            }
            error={!!getIn(paymentFormik.errors, `services[${index}][${header}]`)}
            tooltipHelperText={getIn(
              paymentFormik.errors,
              `services[${index}][${header}]`,
            )}
            type="number"
            isSum="$"
          />
        </S.InputWrapper>
      );
    }
    case 'epsdt': {
      return (
        <S.InputWrapper className="all">
          <TooltipSelectInput
            value={row[header]}
            disabled={checkStatusCondition}
            onChange={(e: SelectChangeEvent<unknown>) =>
              paymentFormik.setFieldValue(`services[${index}].epsdt`, e.target.value)
            }
            label=""
            renderValue={(selected: any) => selected}
            error={!!getIn(paymentFormik.errors, `services[${index}].epsdt`)}
            tooltipHelperText={getIn(paymentFormik.errors, `services[${index}].epsdt`)}
          >
            <MenuItem value={'Yes'}>
              <S.MenuItemContent>Yes</S.MenuItemContent>
            </MenuItem>
            <MenuItem value={'No'}>
              <S.MenuItemContent>No</S.MenuItemContent>
            </MenuItem>
          </TooltipSelectInput>
        </S.InputWrapper>
      );
    }
    case 'renderingProviderId': {
      return (
        <S.InputWrapper className={'all'}>
          <TooltipInput
            label=""
            value={row[header]}
            disabled={
              checkStatusCondition ||
              !!getIn(paymentFormik.values, `services[${index}][${header}]`)
            }
            onChange={e =>
              paymentFormik.setFieldValue(`services[${index}][${header}]`, e.target.value)
            }
            error={!!getIn(paymentFormik.errors, `services[${index}][${header}]`)}
            tooltipHelperText={getIn(
              paymentFormik.errors,
              `services[${index}][${header}]`,
            )}
          />
        </S.InputWrapper>
      );
    }
    case 'trash': {
      const disabled: boolean =
        !!paymentFormik.values.services && paymentFormik.values.services.length < 2;
      return (
        <DeleteRedBtnSVG
          onClick={
            !checkStatusCondition
              ? () => {
                  if (disabled) return;
                  paymentFormik.setFieldValue(
                    'services',
                    paymentFormik.values?.services?.filter((_, i) => i !== index),
                  );
                }
              : undefined
          }
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        />
      );
    }
    default:
      return (
        <S.InputWrapper className={'all'}>
          <TooltipInput
            label=""
            value={row[header]}
            disabled={checkStatusCondition}
            onChange={e =>
              paymentFormik.setFieldValue(`services[${index}][${header}]`, e.target.value)
            }
            error={!!getIn(paymentFormik.errors, `services[${index}][${header}]`)}
            tooltipHelperText={getIn(
              paymentFormik.errors,
              `services[${index}][${header}]`,
            )}
          />
        </S.InputWrapper>
      );
  }
};
